import React from 'react';

const Footer = () => (
  <section className="footerpage">
    <div className="footerpage__container">
      <p style={{ color: 'grey' }}>©2020 Chubi </p>
    </div>
  </section>

);

export default Footer;
